import * as React from "react";
import Typography from "src/components/Typography";
import lockLogo from "src/images/lock-icon.svg";
import { Broadcast, Video as VideoType, VideoRating } from "src/types";
import playIconSvg from "./play-icon.svg";
import styles from "./video.module.css";

type Props = {
  video: VideoType | Broadcast;
  className?: string;
  disabled?: boolean;
};

class VideoPreview extends React.Component<Props> {
  public render() {
    const { video } = this.props;
    if (!video) {
      return null;
    }
    return (
      <div className={this.props.className}>
        <div className={styles.videoElementWrapper}>
          <div className={styles.videoImgWrapper}>
            <div className={styles.iconWrapper}>
              <img
                className={
                  this.props.disabled ? styles.lockIcon : styles.playIcon
                }
                src={this.props.disabled ? lockLogo : playIconSvg}
                alt="play"
              />
            </div>
            <img src={video.previews.m.link} alt={video.name} />
          </div>
          <div className={styles.textWrapper}>
            <Typography type="text">{video.name}</Typography>
            <Typography type="smallerText">
              {video.description || ""}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoPreview;
