import * as React from "react";
import Typography from "src/components/Typography";
import Video from "src/components/Video";
import { Broadcast } from "src/types";
import broadcastModuleCss from "./broadcast.module.css";

interface Props {
  broadcast: Broadcast;
}

class BroadcastHeader extends React.Component<Props> {
  public render() {
    return (
      <div className={broadcastModuleCss.introduction}>
        <div className={broadcastModuleCss.videoWrapper}>
          <Video
            video={this.props.broadcast}
            isBroadcast
            videoPreviewClassName={broadcastModuleCss.mainVideo}
          />
        </div>
        <div className={broadcastModuleCss.headerTextWrapper}>
          <Typography type="h2">
            CINEMARKET — ежегодный кинорынок, объединяющий молодых авторов и
            профессионалов киноиндустрии.
          </Typography>
          <br />
          <div>
            <Typography type="text">
              Кинорынок проходит в Санкт-Петербурге, но в этом году
              присоединиться к нам можно из любой точки мира.
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default BroadcastHeader;
