import { LocationContext } from "@reach/router";
import React from "react";
import Seo from "src/components/Seo";
import Broadcast from "src/routes/Broadcast";

const BroadcastPage = (props: LocationContext) => (
  <>
    <Seo pageTitle="Трансляции" pageDescription="CINEMARKET 2019!" />
    <Broadcast {...props} />
  </>
);

export default BroadcastPage;
