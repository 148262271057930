import { LocationContext } from "@reach/router";
import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import Api from "src/api";
import Typography from "src/components/Typography";
import { Broadcast, User } from "src/types";
import broadcastModuleCss from "./broadcast.module.css";
import BroadcastHeader from "./BroadcastHeader";
import BroadcastsList from "./BroadcastsList";

interface ConnectStateProps {
  user: User;
}

interface Props extends ConnectStateProps, LocationContext {}

interface State {
  broadcasts: Broadcast[];
}

class BroadcastContainer extends React.Component<Props, State> {
  public state = {
    broadcasts: [],
  };

  public isUserHaveAccess = () => {
    return this.props.user.accessLevel === "PART_ACCESS";
  };

  public isUserLogin = () => {
    return !(!this.props.user || !this.props.user.email);
  };

  public async componentDidMount() {
    if (!this.isUserLogin()) {
      await navigate("/register", { state: { modal: true, noScroll: true } });
      return;
    }
    if (!this.isUserHaveAccess()) {
      await navigate("/set-promocode");
      return;
    }
    try {
      await this.getBroadcast();
    } catch (error) {
      console.error(error);
    }
  }

  public render() {
    return (
      <div className={broadcastModuleCss.broadcastContainer}>
        <Typography type="h1">
          Смотрите записи трансляций CINEMARKET 2019!
        </Typography>
        {this.renderData()}
      </div>
    );
  }

  public renderData = () => {
    // tslint:disable-next-line: no-unsafe-any
    if (!this.state.broadcasts || !this.state.broadcasts.length) {
      return null;
    }
    const todayBroadcast = this.state.broadcasts.find(
      (curr: Broadcast) =>
        new Date(curr.startDate).toDateString() === new Date().toDateString()
    );
    return (
      <div className={broadcastModuleCss.broadcastMainWrapper}>
        <BroadcastHeader
          // tslint:disable-next-line: no-unsafe-any
          broadcast={todayBroadcast || this.state.broadcasts[0]}
        />
        <BroadcastsList
          // tslint:disable-next-line: no-unsafe-any
          todayBroadcast={todayBroadcast}
          list={this.state.broadcasts}
        />
      </div>
    );
  };

  private getBroadcast = async () => {
    if (!this.isUserLogin()) {
      return;
    }
    const res = await Api.getBroadcasts();
    this.setState({ broadcasts: res.data });
  };
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
  // tslint:enable no-unsafe-any
});

export default connect<ConnectStateProps>(mapStateToProps)(BroadcastContainer);
