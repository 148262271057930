import * as React from "react";
import Video from "src/components/Video";
import { Broadcast } from "src/types";
import broadcastModuleCss from "./broadcast.module.css";

interface Props {
  todayBroadcast: Broadcast | null;
  list: Broadcast[];
}

class BroadcastList extends React.Component<Props> {
  public render() {
    return (
      <div className={broadcastModuleCss.broadcastsList}>
        {this.getActualList().map((curr: Broadcast, i) => (
          <Video
            key={i}
            video={curr}
            isBroadcast
            videoPreviewClassName={broadcastModuleCss.listItem}
          />
        ))}
      </div>
    );
  }

  public getActualList() {
    const { todayBroadcast, list } = this.props;
    if (todayBroadcast) {
      return list.filter((curr) => curr._id !== todayBroadcast._id);
    }
    return list.filter((_, i) => i !== 0);
  }
}
export default BroadcastList;
