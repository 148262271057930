import { Link } from "gatsby";
import * as React from "react";
import { Video as VideoType, VideoRating } from "src/types";
import styles from "./video.module.css";
import VideoPreview from "./VideoPreview";

interface Props {
  video: VideoType;
  className?: string;
  videoPreviewClassName?: string;
  isMain?: boolean;
  hideDisableIcon?: boolean;
  rating?: VideoRating;
  playlistId?: string;
  withRating?: boolean;
  handleEmptyLinkClick?(): Promise<void>;
}

type State = {
  isOpenModal: boolean;
};

class VideoContainer extends React.Component<Props, State> {
  public render() {
    const { props } = this;

    if (!props.video) {
      return null;
    }

    if (!props.video.link && this.props.handleEmptyLinkClick) {
      return (
        <div onClick={this.props.handleEmptyLinkClick} role="button">
          <VideoPreview
            video={props.video}
            disabled={!this.props.hideDisableIcon}
          />
        </div>
      );
    }

    if (!props.video.link && !this.props.handleEmptyLinkClick) {
      return (
        <Link
          to="/register"
          state={{
            modal: true,
            noScroll: true,
            closeTo:
              typeof window !== `undefined` ? window.location.pathname : "/",
          }}
        >
          <VideoPreview
            video={props.video}
            disabled={!this.props.hideDisableIcon}
          />
        </Link>
      );
    }

    return (
      <div>
        <Link
          to={`/video/${props.video._id}`}
          state={{
            modal: true,
            noScroll: true,
            closeTo:
              typeof window !== `undefined` ? window.location.pathname : "/",
            blackpage: true,
            video: {
              _id: props.video._id,
              link: props.video.link,
              videoId: props.video.videoId,
              name: props.video.name,
              description: props.video.description,
              previews: props.video.previews,
              isBroadcast: props.video.isBroadcast,
            },
            rating: props.rating,
            withRating: props.withRating,
            playlistId: props.playlistId,
          }}
          className={styles.linkWrapper}
        >
          <VideoPreview
            video={props.video}
            className={props.videoPreviewClassName}
          />
        </Link>
      </div>
    );
  }
}

export default VideoContainer;
